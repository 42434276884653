const CUSTOMER_STATUS_DISABLED = 'disabled';

export function toUser(customerId, customer) {
  return {
    id: customerId,
    trusted: customer.trusted,
    trusted_at: customer.trusted_at,
    status: customer.status === CUSTOMER_STATUS_DISABLED ? CUSTOMER_STATUS_DISABLED : 'active',
    first_name: customer.first_name,
    last_name: customer.last_name,
    email: customer.email,
    status_email: customer.email_status || 'unverified',
    notify_email: customer.communication_preferences.includes('email'),
    phone: customer.phone,
    status_sms: customer.phone_status || 'unverified',
    notify_sms: customer.communication_preferences.includes('phone'),
    created_at: customer.created_at,
    updated_at: customer.updated_at,
    deleted_at: customer.deleted_at,
    fraud_risk: customer.fraud_risk,
  };
}
